<template>
  <CommonPages id="home" title="Ai Novel Online" classs="home ">
    <div v-if="recommondList && recommondList.length >0" class="recommand-row ">
      <swiper ref="swiperbanner" class="swiper pc_padding" :options="swiperbannerOption">
        <swiper-slide v-for="(data,i) in recommondList" :key="`recommen_${i}`"
          class="swiper-banner-container">
          <div class="recommand-banner-row cursor"
            @click="chooseNoval(data.novelId,data.id,data.novelName)">
            <div class="recommand-banner-shadow" />
            <img v-lazy="data.bigImg" class="recommand-banner-img" alt="">
            <div class="recomannd-banner-rate-info-row">
              <div class="recomannd-banner-rate-info-tag">
                <div class="recomannd-banner-rate-info-tag-item" v-html="data.novelType" />
              </div>
              <div class="recomannd-banner-rate-info-stars-row">
                <img src="@/assets/star.png" alt=""> {{ data.novelScore }}/10
              </div>
            </div>
          </div>
          <div class="recommand-intro-row cursor"
            @click="chooseNoval(data.novelId,data.id,data.novelName)">
            <div class="recommand-title">
              {{ data.novelName }}
            </div>
            <div class="recommand-author">
              {{ data.novelAuthorName }}
            </div>

            <AdComponent ref="ads-home-1" class="content ad-content-full "
              :ads="adsensConfig.home_1" :showDebug="showDebug" />

            <div class="recommand-content" v-html="data.novelStoryIntro" />
            <div class="recommand-btn cursor">
              Read Now
            </div>
          </div>
        </swiper-slide>
        <div v-if="deviceType != 'mobile'" slot="button-prev" class="swiper-button-prev" />
        <div v-if="deviceType != 'mobile'" slot="button-next" class="swiper-button-next" />

        <div slot="pagination" class="swiper-pagination" />
      </swiper>
    </div>

    <!-- <AdComponent ref="ads-home-2" class="m-b-40 content pc_padding" :ads="adsensConfig.home_2"
      :showDebug="showDebug" /> -->

    <div class="site-intro-container pc_padding">
      <div class="site-title">
        Discover endless creativity with AI-generated novels
      </div>
      <div class="site-content m-t-30 m-b-30">
        Immerse yourself in the fantastical world of AI creatio ns and explore endless stories with
        like-minded read ers.
      </div>
    </div>

    <div class="intro-swiper-container pc_padding">
      <swiper ref="introSwiper" class="intro-swiper" :options="swiperOption">
        <swiper-slide v-for="(data, index) in introSource" :key="index" class="intro-swiper-item">
          <img v-lazy="data.img" alt="" class="intro-swiper-img">
          <div class="intro-swiper-title" v-html="data.title" />
          <div class="intro-swiper-content" v-html="data.content" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="library-container pc_padding">
      <div class="site-title m-b-30">
        Library
      </div>
      <div class="library-tag-row">
        <div v-for="(data,i) in tagList" :key="`tag_${i}`" class="library-tag-item cursor"
          :class="{'active':activeTag == data}" @click="seclecTag(data)">
          {{ data }}
        </div>
      </div>

      <div class="library-row pc_padding">
        <template v-for="(data,i) in novelList">
          <div :key="`lib-${i}`" class="library-item cursor"
            @click="chooseNoval(data.novelId,data.id,data.novelName)">
            <div class="library-item-img-row">
              <div class="library-item-img-shadow" />
              <img v-lazy="data.bigImg" class="library-item-img" alt="">
              <div class="library-item-info-stars-row">
                <img src="@/assets/star.png" alt=""> {{ data.novelScore }}/10
              </div>
            </div>
            <div class="library-item-info-row">
              <div class="library-item-title" v-html="data.novelName" />
              <div class="library-item-autor" v-html="data.novelAuthorName" />
            </div>
            <div class="library-item-type">
              <div class="library-type" v-html="data.novelType" />
            </div>
          </div>
          <!-- 插入广告 -->
          <div v-if="shouldInsertAd(i) !== -1" :key="`ad_${i}`" class="ad-width-100 ad-pos-row">
            <AdComponent :ref="'ads-' + i" class="recommand-ads"
              :ads="adsensConfig.home_recommand[shouldInsertAd(i)]" :showDebug="showDebug" />
          </div>
        </template>
      </div>
    </div>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/pc_common.vue'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import '@/css/common.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
    Swiper,
    SwiperSlide
  },
  mixins: [common],
  data () {
    return {
      swiperbannerOption: {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOption: {
        direction: 'horizontal',
        slidesPerView: "auto",
        centeredSlides: true,// 居中的slide是否标记为active，默认是最左active,这样样式即可生效
        loop: true, // 开启无限循环
      },
      introSource: [
        { img: require('@/assets/icon-1.png'), title: 'Unlimited Creative Source', content: 'Users can upload and create AI-generated novels, exploring endless creativity and storylines.' },
        { img: require('@/assets/icon-2.png'), title: 'Diverse Genre Exploration', content: 'We offer a wide range of genres, allowing users to explore and enjoy various types of stories, from science fiction to romance.' },
        { img: require('@/assets/icon-3.png'), title: 'Enjoy instant updates', content: 'Reading the latest AI-generated stories as soon as they are available, so you never miss out on exciting content.' },
      ],

      novelList: [],
      tagList: null,
      recommondList: [],

      page: 1,   // 当前页数
      hasNext: false,
      activeTag: null,

      ////////
    };
  },
  metaInfo () {
    return {
      title: 'AiNovelOnline.com - AI-Generated Novels and Stories',
      meta: [
        {
          name: 'description',
          content: 'Discover a world of AI-generated novels and stories at AiNovelOnline.com. Dive into unique, imaginative tales created by advanced artificial intelligence. Perfect for readers seeking fresh and innovative content.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.getRecommand()
    this.getTagList()
    await this.getPageList()
    this.loadAdSenseScript();  // 重新加载广告脚本

    // 使用防抖包装滚动事件
    this.onScroll = this.debounce(this.onScroll.bind(this), 300);
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll);
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    // window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    //shuju
    async getRecommand () {
      try {
        const res = await this.$global.server.getRecommand({ noveLang: 'en' })
        console.log(res)
        if (res.code == 200) {
          let data = res.data.novelList
          this.recommondList.push(...data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getTagList () {
      try {
        const res = await this.$global.server.getTagList({ noveLang: 'en' })
        console.log(res)
        this.tagList = res.data

      } catch (err) {
        console.log(err)
      }
    },
    async getPageList () {
      const params = {
        novelLang: 'en',
        pageNum: this.page,
        pageSize: 30,
      };
      // 只有当 this.tag 不为 null 时，才添加 tag 字段
      if (this.activeTag !== null) {
        params.novelType = this.activeTag;
      }

      try {
        const res = await this.$global.server.getPageList(params)
        console.log(res)
        if (res.code == 200) {
          let data = res.data.novelList
          this.novelList.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
      } catch (err) {
        console.log(err)
      }
    },
    seclecTag (tag) {
      if (this.activeTag == tag) return
      console.log(tag);
      this.activeTag = tag
      this.page = 1;   // 当前页数
      this.hasNext = false;
      this.novelList = []
      this.getPageList()
    },
    chooseNoval (name, id) {
      console.log(name, id);
      window.location.href = `/${name}/${id}/0`
    },
    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        this.getPageList();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig.homeRecommand.indexOf(position) : this.adPosConfig.homeRecommandPC.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds('ads-'); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });
      console.log(adsElements);

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;  // 如果是 Vue 实例，获取它的 $el
        if (adElement) {
          observer.observe(adElement); // 开始监听广告元素是否进入可视区域
          console.log('Observing ad element:', adElement);
        }
      });
    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //ciyi

    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
